<template>
  <div id="openQuestionInput">
    <div class="poll-head poll-head__button">
      <span class="question question__input ffr fw500">
        {{languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish}}*
      </span>
      <b-field>
        <b-input
          v-model="pollResp"
          type="text"
          @input="setValue()"
          class="input_poll ffr fw400"
          :placeholder="`${languagePoll === 'es' ? 'Escribe tu respuesta' : 'Write your answer'}`"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>
<script>
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      pollResp: null
    };
  },
  mounted () {},
  methods: {
    setValue () {
      this.$emit('input', this.pollResp);
    }
  }
};
</script>
<style lang="scss">
#openQuestionInput {
  .input_poll {
    margin-top: 16px;
    max-height: none !important;
    input {
      background: #e0e2e2;
      height: 38px !important;
      width: 100%;
      color: #444b57;
      font-size: 10px !important;
      border: none;
    }
    input::placeholder {
      color: #757a86;
    }
  }
  @media screen and (max-width:780px) {
    .input_poll {
      input {
        width: 100%;
      }
    }
  }
}
</style>
