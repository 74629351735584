<template>
  <div id="pollLanguage" @click="$emit('changeLanguagePoll')">
    <div class="language-poll-selector">
      <div><span :class="{'language-poll-selector--bold': languagePoll === 'es'}">ES</span> / <span :class="{'language-poll-selector--bold': languagePoll === 'en'}">EN</span></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['languagePoll']
};
</script>

<style lang="scss">
  #pollLanguage{
    border-radius: 8px;
    z-index: 2000;
    min-width: 54px;
    width: fit-content;
    text-align: center;
    justify-content: center;
    height: 23px;
    padding: 4px 6px;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    position: fixed;
    top: 23px;
    right: 18px;
    cursor: pointer;
    .language-poll-selector{
      &--bold{
        font-weight: 700;
      }
    }
  }
  @media screen and (min-width: 1280px ) {
    #pollLanguage{
      right: 100px!important;
      cursor: pointer;
      .language-poll-selector{
      }
    }
  }
  @media screen and (min-width: 1024px ) {
    #pollLanguage{
      right: 50px;
      cursor: pointer;
      .language-poll-selector{
      }
    }
  }
  @media screen and (max-width: 768px ) {
    #pollLanguage{
      right: 20px;
      cursor: pointer;
      .language-poll-selector{
      }
    }
  }
  @media screen and (max-width: 500px ) {
    #pollLanguage{
      right: 18px;
      cursor: pointer;
      .language-poll-selector{
      }
    }
  }
</style>
