<template>
  <div id="yesOrNo">
    <div class="poll_head">
      <span class="question ffr fw500">{{languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish}}*</span>
      <div class="cont_radio">
        <div :key="key" class="options" v-for="(option, key) in subQuestions">
          <b-radio
            :native-value="option.option"
            @input="setValue()"
            class="radioOption"
            size="is-small"
            v-bind:name="'radio' + name"
            v-model="radioValue"
          ></b-radio>
          <span class="radioText ffr fw400">{{ languagePoll === 'es' ? option.option : option.optionEnglish }}</span>
        </div>
      </div>
      <div
        style="display: flex; align-items: center; justify-content: center; gap: 5px;"
        v-if="radioExtra"
      >
        <div style="color: #4A4A4A;  height: 10px;">{{languagePoll === 'es' ? '¿Porqué?': 'Why?'}}</div>
        <div style="flex: 1;">
          <b-field>
            <b-input
              @input="setValue()"
              class="input_poll ffr fw400"
              :placeholder="`${languagePoll === 'es' ? 'Escribe tu respuesta' : 'Write your answer'}`"
              type="text"
              v-model="pollResp"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      subQuestions: [],
      name: null,
      radioValue: null,
      radioExtra: 0,
      pollResp: null
    };
  },
  mounted () {
    this.subQuestions = this.questions.opciones;
    this.name = this.questions.id;
  },
  methods: {
    setValue () {
      const extra = this.subQuestions.find(sub => sub.option === this.radioValue);
      extra['extraValue'] = this.pollResp;
      this.radioExtra = extra && extra.extra;
      this.$emit('input', extra);
    }
  }
};
</script>
<style lang="scss">
#yesOrNo {
  .question {
    font-size: 13px;
    color: #444b57;
  }
  .radioText {
    font-size: 13px;
    color: #444b57;
  }
  .options {
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
  }
  .cont_radio {
    display: flex;
    flex-wrap: wrap;
  }
  .b-radio.radio:not(.button) {
    margin-right: 0;
    margin-top: 2px;
  }
  .b-radio.radio input[type="radio"] + .check {
    border-radius: 4px;
    border: 0.5px solid #444b57;
    width: 18px;
    height: 18px;
  }
  .b-radio.radio input[type="radio"] + .check:before {
    border-radius: 4px;
    background: #444b57;
    margin-left: calc(-18px * 0.5);
    margin-bottom: calc(-18px * 0.5);
    width: 18px;
    height: 18px;
  }
  .input_poll {
    margin-top: 16px;
    max-height: none !important;
    input {
      background: #e0e2e2;
      height: 38px !important;
      width: 100%;
      color: #444b57;
      font-size: 10px !important;
      border: none;
      border-radius: 8px;
    }
    input::placeholder {
      color: #757a86;
    }
  }
  @media screen and (max-width: 780px) {
    .input_poll {
      input {
        width: 100%;
      }
    }
  }
}
</style>
