<template>
  <div id="multiRadio">
    <div class="desk">
      <div class="poll-head">
        <span class="question left-text">{{ this.questions.question }}</span>
        <div v-for="(option, key) in options" :key="key" class="options">
          {{ option }}
        </div>
      </div>
      <div class="poll-body">
        <div
          v-for="(subQuestion, key) in subQuestions"
          :key="key"
          class="subQuestion"
        >
          <span class="left-text subq">{{ subQuestion.option }}</span>
          <div v-for="(option, key) in options" :key="key" class="options">
            <b-radio
              size="is-medium"
              v-model="radioValue[subQuestion.id]"
              v-bind:name="subQuestion.option"
              :native-value="option"
              @input="setValue(subQuestion.id)"
            >
            </b-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="mob">
      <div class="poll-head">
        <span class="question left-text">{{ languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish }}</span>
      </div>
      <div class="poll-body">
        <div
          v-for="(subQuestion, key) in subQuestions"
          :key="key"
          class="subQuestion"
        >
          <span class="left-text subq">{{ languagePoll === 'es' ? subQuestion.option : subQuestion.optionEnglish }}</span>
          <div class="contOptions">
            <div v-for="(option, key) in options" :key="key" class="options">
              {{ option }}
            </div>
          </div>
          <div class="radioCont">
            <div v-for="(option, key) in options" :key="key" class="options">
              <b-radio
                size="is-medium"
                v-model="radioValue[subQuestion.id]"
                v-bind:name="subQuestion.option"
                :native-value="option"
                @input="setValue(subQuestion.id)"
              >
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      options: [],
      subQuestions: [],
      radiosCont: 0,
      questionCont: 0,
      radioValue: [],
      dataReturn: []
    };
  },
  mounted () {
    this.options = this.languagePoll === 'es' ? this.questions.sub_option.split(',') : this.questions.subOptionsEnglish.split(',');
    this.radiosCont = this.options.length;
    this.subQuestions = this.questions.opciones;
    this.questionCont = this.subQuestions.length;
  },
  methods: {
    setValue (questionId) {
      let pos = 0;
      let i = {
        questionId: questionId,
        value: this.radioValue[questionId]
      };
      let flag = _.find(this.dataReturn, { questionId: questionId });
      if (flag === undefined) {
        this.dataReturn.push(i);
      } else {
        pos = _.findIndex(this.dataReturn, { questionId: questionId });
        this.dataReturn[pos].value = this.radioValue[questionId];
      }
      if (this.dataReturn.length === this.questionCont) {
        this.$emit('input', this.dataReturn);
      }
    }
  },
  watch: {
    languagePoll (value) {
      this.options = value === 'es' ? this.questions.sub_option.split(',') : this.questions.subOptionsEnglish.split(',');
    }
  }
};
</script>
<style lang="scss">
#multiRadio {
  .mob {
    display: none;
  }
  border-bottom: 1px solid #444b57d5;
  padding: 10px;
  .left-text {
    display: flex;
    min-width: 220px;
    width: 220px;
    justify-content: flex-start;
    color: #444b57;
    text-align: left;
    font-size: 18px;
  }
  .poll-head {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .question {
      font-weight: bold;
      font-size: 22px;
    }
    .options {
      font-weight: bold;
      width: 115px;
      min-width: 115px;
      padding: 2px;
      color: #444b57;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .poll-body {
    display: flex;
    flex-direction: column;
    .subQuestion {
      display: flex;
      flex-direction: row;
      .subq {
        padding: 5px;
        display: flex;
        align-items: center;
      }
    }
    .options {
      width: 115px;
      min-width: 115px;
      color: #444b57;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      .b-radio.radio input[type="radio"]:checked + .check {
        border-color: #696f79;
      }
      .b-radio.radio input[type="radio"] + .check:before {
        background-color: #696f79 !important;
      }
      .b-radio.radio input[type="radio"]:checked + .check:before {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
      }
      .b-radio.radio {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@media (max-width: 2000px) {
  #multiRadio {
    width: 100%;
    padding: 0px;
    .desk {
      display: none;
    }
    .mob {
      display: block;
    }
    .left-text {
      min-width: 280px;
      width: 280px;
      margin-bottom: 10px;
    }
    .poll-body {
      display: flex;
      flex-direction: column;
      .subQuestion {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .subq {
          display: flex;
          align-items: center;
          padding: 0px;
        }
        .contOptions {
          display: flex;
          justify-content: space-around;
        }
        .radioCont {
          display: flex;
          justify-content: space-around;
        }
      }
      .options {
        width: 86px;
        min-width: 86px;
        color: #444b57;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 13px;
        font-weight: bold;
        margin-right: -10px;
        .b-radio.radio input[type="radio"]:checked + .check {
          border-color: #696f79;
        }
        .b-radio.radio input[type="radio"] + .check:before {
          background-color: #696f79 !important;
        }
        .b-radio.radio input[type="radio"]:checked + .check:before {
          -webkit-transform: scale(0.7);
          transform: scale(0.7);
        }
        .b-radio.radio {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
