 <template>
  <div id="radioQuestion">
    <div class="poll-head poll-head__button">
      <span class="question question__input">{{
        languagePoll === 'es' ? this.questions.question : this.questions.questionEnglish
      }}</span>
      <b-field>
        <b-clockpicker
          icon="clock"
          v-model="pollResp"
          @input="setValue()"
          hour-format="12"
          locale="es-Es"
        >
        </b-clockpicker>
      </b-field>
    </div>
  </div>
</template>
<script>
export default {
  props: ['questions', 'languagePoll'],
  data () {
    return {
      pollResp: null
    };
  },
  mounted () {},
  methods: {
    setValue () {
      const newDate = this.$moment(this.pollResp).format('hh:mm');
      this.$emit('input', newDate);
    }
  }
};
</script>
<style lang="scss">
#openQuestion2 {
  width: 815px;
  display: flex;
  justify-content: center;
  .poll-head {
    .input {
      color: blue;
    }
    &__button {
      flex-direction: row;
    }
    &__question__left {
      justify-content: flex-start;
    }
  }
  .label {
    margin-bottom: 15px;
    font-size: 18px;
    color: #444b57;
    font-weight: bold;
  }
  .textField {
    .textarea {
      min-width: 815px;
      width: 815px;
      border: 1px solid #696f78;
      border-radius: 0;
    }
  }
}
</style>
